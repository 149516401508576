'use strict';

const computedStyles = getComputedStyle(document.documentElement);

/**
 * @constant {Object} events - All common events
 * @property {string} blur - Blur event
 * @property {string} change - Change event
 * @property {string} click - Click event
 * @property {string} keyUp - Keyup event
 * @property {string} load - Load event
 * @property {string} mousedown - Mouse down event
 * @property {string} mouseleave - Mouse leave event
 * @property {string} mouseover - Mouse over event
 * @property {string} popstate - Popstate event
 * @property {string} resize - Resize event
 * @property {string} scroll - Scroll event
 * @property {string} submit - Submit event
 * @property {string} update - Custom update event
 */
const events = {
    blur: 'blur',
    change: 'change',
    click: 'click',
    input: 'input',
    keyUp: 'keyup',
    load: 'load',
    mousedown: 'mousedown',
    mouseleave: 'mouseleave',
    mouseover: 'mouseover',
    paste: 'paste',
    popstate: 'popstate',
    resize: 'resize',
    responseError: 'responseError',
    scroll: 'scroll',
    submit: 'submit',
    transitionEnd: 'transitionend',
    update: 'update',
    invalid: 'invalid'
};

/**
 * @constant {Object} selectors - Common selectors
 * @property {string} body - body
 * @property {Object} form - form related selectors
 * @property {string} checkbox - checkbox
 * @property {string} form - form
 * @property {string} input - input
 * @property {string} inputFieldClear - .js-input-clear
 * @property {string} inputFieldFeedback - .js-invalid-feedback
 * @property {string} label - label
 * @property {string} radio - radio
 * @property {string} select - select
 * @property {string} submit - [type="submit"]
 * @property {string} textarea - textarea
 * @property {string} html - html
 * @property {string} htmlBody - html, body
 * @property {string} img - img
 * @property {string} input - input
 */
const selectors = {
    body: 'body',
    form: {
        checkbox: 'input[type="checkbox"]',
        file: 'input[type="file"]',
        form: 'form',
        input: 'input',
        inputFieldClear: '.js-input-clear',
        inputFieldFeedback: '.js-invalid-feedback',
        label: 'label',
        radio: 'input[type="radio"]',
        select: 'select',
        submit: '[type="submit"]',
        textarea: 'textarea'
    },
    html: 'html',
    htmlBody: 'html, body',
    img: 'img',
    input: 'input',
    interactiveElements: 'input, button, select, textarea, a[href]'
};

/**
 * @constant {Object} classes - Common classes
 * @property {string} active - active
 * @property {Object} buttons - buttons
 * @property {string} next - next
 * @property {string} previous - previous
 * @property {string} warning - warning
 * @property {string} dBlock - d-block
 * @property {string} dFlex - d-flex
 * @property {string} dInlineBlock - d-inline-block
 * @property {string} dNone - d-none
 * @property {string} disabled - disabled
 * @property {string} empty - empty
 * @property {string} inactive - inactive
 * @property {string} invalid - invalid
 * @property {string} hidden - hidden
 * @property {string} last - last
 * @property {string} show - show
 * @property {string} valid - valid
 */
const classes = {
    active: 'active',
    buttons: {
        next: 'js-button-next',
        previous: 'js-button-previous',
        warning: 'js-button-warning'
    },
    dBlock: 'd-block',
    dFlex: 'd-flex',
    dInlineBlock: 'd-inline-block',
    dNone: 'd-none',
    disabled: 'disabled',
    empty: 'empty',
    inactive: 'inactive',
    invalid: 'invalid',
    hidden: 'hidden',
    last: 'last',
    show: 'show',
    valid: 'valid'
};

/**
 * @constant {Object} attributes - Common attributes
 * @property {string} action - action
 * @property {string} class - class
 * @property {string} csrfToken - data-csrf-token
 * @property {string} csrfName - data-csrf-name
 * @property {string} disabled - disabled
 * @property {string} exclude - data-exclude
 * @property {string} href - href
 * @property {string} id - id
 * @property {string} pattern - pattern
 * @property {string} url - url
 * @property {string} readonly - readonly
 * @property {string} target - data-bs-target
 * @property {string} value - value
 * @property {Object} validation - Validation related attributes
 * @property {string} patternMismatch - data-pattern-mismatch
 * @property {string} valueMissing - data-missing-error
 */
const attributes = {
    action: 'action',
    class: 'class',
    checked: 'checked',
    csrfToken: 'data-csrf-token',
    csrfName: 'data-csrf-name',
    disabled: 'disabled',
    exclude: 'data-exclude',
    href: 'href',
    id: 'id',
    pattern: 'pattern',
    placeholder: 'placeholder',
    required: 'required',
    readonly: 'readonly',
    selected: 'selected',
    target: 'data-bs-target',
    url: 'url',
    value: 'value',
    validation: {
        patternMismatch: 'data-pattern-mismatch',
        valueMissing: 'data-missing-error',
        tooShort: 'data-too-short'
    }
};

/**
 * @constant {Object} breakpoints - Common reusable breakpoints
 * @property {string} xs - 0
 * @property {string} sm - 768
 * @property {string} md - 1024
 * @property {string} lg - 1440
 * @property {string} xl - 1920
 */

const breakpoints = {
    xs: parseInt(computedStyles.getPropertyValue('--breakpoint-xs'), 10),
    sm: parseInt(computedStyles.getPropertyValue('--breakpoint-sm'), 10),
    md: parseInt(computedStyles.getPropertyValue('--breakpoint-md'), 10),
    lg: parseInt(computedStyles.getPropertyValue('--breakpoint-lg'), 10),
    xl: parseInt(computedStyles.getPropertyValue('--breakpoint-xl'), 10)
};

/**
 * @constant {Object} localStorage - constants for local storage items
 * @property {string} preferredLayoutId - preferredLayoutId
 */
const localStorage = {
    preferredLayoutId: 'preferredLayoutId'
};

/**
 * @constant {Object} bscanSteps - constants for Business Scan  Steps
 * @property {string} ADVICE - The advice Step
 * @property {string} INTRO - The intro Step
 * @property {string} GROUPED_QUESTIONS - The grouped questions Step
 * @property {string} THANKYOU - The thank you Step
 */
const bscanSteps = {
    INTRO: 'intro',
    STATEMENTS_DONE: 'bs-statements-done',
    GROUPED_QUESTIONS: 'js-bscan-question-group',
    ADVICE: 'bs-advice',
    THANKYOU: 'bs-thank-you'
};

/**
 * @constant {Object} vzLayoutToggle - Attributes used for VZLayoutToggle
 * @property {string} class - data-layout-class
 * @property {string} container - data-layout-container
 * @property {string} enabledBreakpoint - data-layout-enabled-breakpoint
 * @property {string} id - data-layout-id
 * @property {string} idDefault - data-layout-id-default
 * @property {string} localStorageKey - data-layout-key
 */
const vzLayoutToggle = {
    class: 'data-layout-class',
    container: 'data-layout-container',
    enabledBreakpoint: 'data-layout-enabled-breakpoint',
    id: 'data-layout-id',
    idDefault: 'data-layout-id-default',
    localStorageKey: 'data-layout-key'
};

export default {
    bscanSteps,
    attributes,
    breakpoints,
    classes,
    events,
    selectors,
    localStorage,
    vzLayoutToggle
};
