'use strict';

import { documentReady, isFlexContainerWrapped } from 'Util/util';
import * as debounce from 'lodash/debounce';

/**
 * Adjusts the alignment of CTA elements within card components
 * to ensure proper layout when the CTAs are wrapped in multiple lines.
 */
function adjustCtaAlignment() {
    const cardComponents = document.querySelectorAll('.experience-commerce_assets-card');
    Array.from(cardComponents).forEach((component) => {
        const ctaWrapper = component.querySelector('.js-cta-wrapper');
        const ctaCollection = ctaWrapper && ctaWrapper.querySelectorAll('.js-card-cta:not(.btn-full-width-card)');
        if (ctaCollection && ctaCollection.length > 0) {
            Array.from(ctaCollection).forEach((cta) => {
                cta.classList.remove('btn-wrapped');
                cta.querySelector('a')?.classList.remove('btn-wrapped');
            });
            if (isFlexContainerWrapped(ctaWrapper, ctaCollection, 18)) {
                Array.from(ctaCollection).forEach((cta) => {
                    cta.classList.add('btn-wrapped');
                    cta.querySelector('a')?.classList.add('btn-wrapped');
                });
            }
        }
    });
}

documentReady(adjustCtaAlignment);
window.addEventListener('resize', debounce(adjustCtaAlignment), 150);
