'use strict';

import CONST from './constants';

/**
 * addEvent - Will add an event listener to an element inside a parent
 *
 * @param {string} parent - The parent element
 * @param {string} passedEvent - The event
 * @param {string} selector - The targeted element
 * @param {function} handler - The handler
 */
function addEvent(parent, passedEvent, selector, handler) {
    parent.addEventListener(
        passedEvent,
        function fnAddEvent(event) {
            // If the clicked element matches the selector, or is a child of the selector
            if (event.target.matches(`${selector} , ${selector} *`)) {
                handler.apply(event.target.closest(selector), arguments);
            }
        },
        false
    );
}

/**
 * @public documentReady
 * @param {Function} fn - function to be called after document is done loading.
 */
function documentReady(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

/**
 * @public exists
 * @param {*} selection - The element or nodelist to verify for existence
 * @returns {boolean} - Boolean value stating the existance of the selection
 * @description
 * Verifies existance of a certain element by checking for type, null and length
 * Usage: use with either querySelector() or querySelectorAll()
 * querySelector will not have a 'length', e.g. this would return true if other conditions are true
 * querySelectorAll will have a 'length', e.g. this would return true if all conditions are true
 */
function exists(selection) {
    return typeof selection !== 'undefined' && selection !== null && ('length' in selection ? selection.length > 0 : true);
}

/**
 * @public getPosition
 * @param {*} element - The element to get the position for
 * @returns {Object} - An object containing the left and top position of the element
 *
 * @description
 * Will get the position of an element relative to the document
 */
function getPosition(element) {
    let top = element.offsetTop;
    let left = element.offsetLeft;

    let currentElement = element;
    while (currentElement.offsetParent && currentElement.offsetParent !== window) {
        currentElement = currentElement.offsetParent;
        top += currentElement.offsetTop;
        left += currentElement.offsetLeft;
    }

    return { left, top };
}

/**
 * isKeyInObject
 * @param {Object} object - The object
 * @param {string} key - The key
 * @returns {boolean} - True or false
 */
function isKeyInObject(object, key) {
    return Object.prototype.hasOwnProperty.call(object, key);
}

/**
 * @private isAemMobileView
 * @returns {boolean} - Whether the user is on a "mobile" viewport or not based on AEM rules
 *
 * @description
 * Checks to see whether the user is on a "mobile" viewport or not based on AEM rules
 * Please note that www.vodafone.nl/zakelijk will show the mobile menu from viewports 1023 and smaller
 */
function isAemMobileView() {
    return window.innerWidth < 1024;
}

/**
 * @memberof util
 * @param {string} breakpointKey from constants that should match css breakpoint
 * @param {boolean} [isUp] by default is true since mobile first, reverses condition
 * @return {boolean} whether window is larger than specified breakpoint
 */
function isMediaBreakpoint(breakpointKey, isUp = true) {
    if (!isKeyInObject(CONST.breakpoints, breakpointKey)) {
        console.error(`fn isMediaBreakpoint: ${breakpointKey} -- does not exist in constants breakpoints object`); // eslint-disable-line
    }

    const outerWidth = window.innerWidth;
    const breakpointValue = parseInt(CONST.breakpoints[breakpointKey], 10);

    return isUp ? outerWidth > breakpointValue : outerWidth < breakpointValue - 1;
}

/**
 * @memberof util
 * @return {Object} An object containing the name and value of the current breakpoint
 */
function getCurrentBreakpoint() {
    const breakpoints = CONST.breakpoints;
    const screenSize = document.documentElement.clientWidth;
    const currentBreakpoint = [...Object.keys(breakpoints)].reverse().find((key) => screenSize > breakpoints[key]);

    return {
        name: currentBreakpoint,
        value: breakpoints[currentBreakpoint]
    };
}

/**
 * @memberof util
 * @return {number} a unique number
 */
function generateUniqueNumber() {
    return performance.now().toString().replace('.', 0);
}

/**
 * @public empty
 * @param {*} element - The element to remove child nodes from
 * @description
 * Empties a given element completely while the given element has child nodes.
 * Plain strings are also considered child nodes and are thus also removed.
 */
function removeChildNodes(element) {
    while (element.hasChildNodes()) {
        element.removeChild(element.lastChild);
    }
}

/**
 * @public tryParseJSON
 * @param {string} string - The possible JON string
 * @returns {Object} - Either the JSON object or an empty object for consistent return
 * @description
 * Copied from https://stackoverflow.com/a/20392392
 */
function tryParseJSON(string) {
    try {
        const o = JSON.parse(string);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === 'object') {
            return o;
        }
    } catch (e) {} // eslint-disable-line no-empty

    return {};
}

/**
 * @function isIOS
 * @public
 * @description returns a boolean to determine whether a device's OS is IOS or not
 * @returns {boolean} whether the device OS is IOS or not
 */
function isIOS() {
    return (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;
}

/**
 * @function isMobile
 * @returns {boolean} whether the device is an mobile device or not
 */
function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

/**
 * @public scrollTo
 * @param {Object} data - The optional object containing the top & left positions to scroll to
 */
function scrollTo(data) {
    const position = data || {
        top: 0,
        left: 0
    };

    const stickyHeader = document.querySelector('.page-header');
    const headerHeight = stickyHeader ? stickyHeader.offsetHeight : 0;
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let positionTop = position.top;

    if (headerHeight > 0) {
        positionTop = position.top - headerHeight - 15; // Removing an additional 15px to put some space between the sticky site header and the element
    }

    if (supportsNativeSmoothScroll) {
        document.documentElement.scrollTo({
            top: positionTop,
            left: position.left,
            behavior: 'smooth'
        });
    } else {
        document.documentElement.scrollTop = positionTop;
    }
}

/**
 * @function toggleButtonState
 * @description Enables or disabled the button passed in based on the value of the second parameter.
 * @param {HTMLElement} button - A button element to be enabled or disabled.
 * @param {boolean} enable - True for enable and false for disable.
 */
function toggleButtonState(button, enable) {
    if (enable) {
        button.classList.remove('disabled');
        button.removeAttribute('disabled');
    } else {
        button.classList.add('disabled');
        button.setAttribute('disabled', 'disabled');
    }
}

/**
 * @function removeAccentCharacters
 * @description Removes a accents from a string.
 * @param {string} string - The string from which we want to remove accents.
 * @returns {string} The string without accents.
 */
function removeAccentCharacters(string) {
    return string.normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

/**
 * @function chunkArrayInGroups
 * @param {Array} arr - Array to be splitted
 * @param {number} size - Max size of the array chunk
 * @return {Array} - Array of grouped chunks
 * @description Splitting an array up into chunks of a given size
 */
function chunkArrayInGroups(arr, size) {
    var groupedChunks = [];
    for (let i = 0; i < arr.length; i += size) {
        groupedChunks.push(arr.slice(i, i + size));
    }
    return groupedChunks;
}

/**
 * @function chunkGroupArrayInGroups
 * @param {Array} groupArr - Array to be splitted
 * @return {Array} - Array of grouped chunks
 * @description Splitting an array up into chunks of a given size
 */
function chunkGroupArrayInGroups(groupArr) {
    // This one sorts and groups the alike product groups together
    // We need to send the requests to backend in a specific order, so we make it grouped
    const chunkGroups = [];
    Object.keys(groupArr).forEach((groupKey) => {
        const arr = groupArr[groupKey];
        const groupedChunks = [];
        // Sorts the product objects based on pids
        arr.sort((a, b) => {
            return a.pid >= b.pid ? 1 : -1;
        });
        for (let i = 0; i < arr.length; i += Math.round(arr.length / 3)) {
            groupedChunks.push(arr.slice(i, i + Math.round(arr.length / 3)));
        }
        // This makes sure that we have a separate business mobile group to send to backend
        groupedChunks.forEach((chunk, chunkIndex) => {
            Object.keys(chunk).forEach((key, index) => {
                const item = chunk[key];
                if (item?.pdpConfiguratorType === 'business-mobile') {
                    chunk.splice(index, 1);
                    if (chunk.length < 1) groupedChunks.splice(chunkIndex, 1);
                    groupedChunks.push(item);
                }
            });
        });
        chunkGroups.push(groupedChunks);
    });
    return chunkGroups;
}

/**
 * @function trackPdpEccomerceEvent
 * @param {string} productType - Configurator type
 * @param {Object} step - Step to track
 * @description Builds and submits ecommerce event to track btn clicks on PDP
 */
function trackPdpEccomerceEvent(productType, step) {
    const products = {
        brand: 'vodafone',
        category: `business-sme/acquisition/postpaid/${productType.replace(/\s|business-/gi, '')}`,
        market: 'b2b'
    };

    const ecommerceObject = {
        event: 'checkout',
        events: {
            category: 'ecommerce',
            action: 'checkout'
        },
        ecommerce: {
            checkout: {
                action: 'checkout',
                has_vodafone: true,
                has_ziggo: true,
                products: products,
                step_name: step.name,
                step_nr: step.number
            }
        }
    };

    /* eslint-disable no-underscore-dangle */
    if (ecommerceObject && window._ddm) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(ecommerceObject);
        window._ddm.trigger('ecommerce.checkout', {
            data: ecommerceObject.ecommerce.checkout
        });
    }
    /* eslint-enable no-underscore-dangle */
}

/**
 * @function assignStyling
 * @private
 * @param {Element} el - Element
 * @param {Object} styles - Styles
 * @description Assign a style object to an element
 */
function assignStyling(el, styles) {
    Object.assign(el.style, styles);
}

/**
 * Calculate the total width of a collection of elements.
 *
 * @param {NodeListOf<HTMLElement>} elements - A collection of HTML elements.
 * @param {number} spacing - flex gap value in px.
 * @returns {number} The total width of all elements.
 */
function getTotalWidth(elements, spacing = 0) {
    return Array.from(elements).reduce((totalWidth, element) => {
        const elementStyles = window.getComputedStyle(element);
        const elementWidth = element.offsetWidth + parseFloat(elementStyles.marginLeft) + parseFloat(elementStyles.marginRight);
        return totalWidth + elementWidth;
    }, spacing);
}

/**
 * Checks if a flex container is wrapped by comparing the total width of its flex items
 * with the container's width.
 *
 * @param {HTMLElement} flexContainer - The HTML element representing the flex container.
 * @param {NodeListOf<HTMLElement>} flexItems - A collection of HTML elements representing the flex items.
 * @param {number} spacing - flex gap value in px.
 * @returns {boolean} True if the flex container is wrapped, false if all items fit in a single row.
 */
function isFlexContainerWrapped(flexContainer, flexItems, spacing) {
    const containerWidth = flexContainer.clientWidth;
    const itemsTotalWidth = getTotalWidth(flexItems, spacing);
    // Check if the items are wrapped by comparing their total width with the container width.
    const isWrapped = itemsTotalWidth > containerWidth;
    return isWrapped;
}

/**
 * @function loadCSS
 * @param {string} url - The URL of the CSS file to load.
 * @description Creates a link element and appends it to the document head to load a CSS file.
 */
function loadCSS(url) {
    if (!url || document.querySelector(`link[href="${url}"]`)) {
        return;
    }
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.head.appendChild(link);
}

/**
 * The function formats input to money string
 *
 * @param {number} num Source number
 * @returns {string} Formatted string € 1.111,11
 */
function formatToPriceString(num) {
    const parts = Number.parseFloat(num, 10).toFixed(2).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (parts[1] === '00') {
        return `€ ${parts[0]},00`;
    }
    return `€ ${parts.join(',')}`;
}

/**
 * Checks if two arrays of objects are equal by comparing their elements.
 *
 * @param {Array} arr1 - The first array to compare.
 * @param {Array} arr2 - The second array to compare.
 * @returns {boolean} True if the arrays are equal, false otherwise.
 */
function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    return (
        !arr1.some((obj1) => !arr2.some((obj2) => JSON.stringify(obj1) === JSON.stringify(obj2))) &&
        !arr2.some((obj2) => !arr1.some((obj1) => JSON.stringify(obj1) === JSON.stringify(obj2)))
    );
}

export {
    CONST,
    addEvent,
    documentReady,
    exists,
    generateUniqueNumber,
    getCurrentBreakpoint,
    getPosition,
    isIOS,
    isMobile,
    isKeyInObject,
    isAemMobileView,
    isMediaBreakpoint,
    removeChildNodes,
    scrollTo,
    tryParseJSON,
    toggleButtonState,
    removeAccentCharacters,
    chunkArrayInGroups,
    chunkGroupArrayInGroups,
    trackPdpEccomerceEvent,
    assignStyling,
    isFlexContainerWrapped,
    getTotalWidth,
    loadCSS,
    formatToPriceString,
    arraysAreEqual
};
